export default {
  items: [
    {
      name: 'Dashboard',
      url: '/dashboard',
      icon: 'icon-speedometer',
    },

    {
      name: 'Environments',
      url: '/theme/colors',
      icon: 'icon-rocket',
    },


  ],
};
