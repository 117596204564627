import './polyfill'
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import Keycloak from 'keycloak-js';
import AuthStore from './AuthStore'

var keycloak = new Keycloak('/keycloak.json')

// Initiate the Keycloak adapter and force login BEFORE rendering any output
keycloak.init({ onLoad: 'login-required' })

    .success(function() {

        localStorage.setItem('bearer_token', keycloak.token);

        keycloak.loadUserProfile()
            .success(function(profile) {
                ReactDOM.render(
                <AuthStore
                    userProfile = {profile}
                    token = {keycloak.token}
                    logoutUrl = {keycloak.createLogoutUrl()}
                >
                <App /></AuthStore>, document.getElementById('root'));
            })
            .error(function() {
                console.error('Failed to load the userprofile, verify if the user exists in the realm.');
            });
    })
    .error(function() {
        console.error('Failed to connect to Keycloak, verify if the server is accessible from the kermit ui container.');
    });

// ---- Callback handlers for authentication

// If token is expired try to refresh it, logout if it fails
keycloak.onTokenExpired  = function() { 
    keycloak.updateToken(5)
        .success(function()  {
            localStorage.setItem('bearer_token', keycloak.token);
        })
        .error(function() {
            keycloak.logout();
            window.location.reload(); 
        });
}

// Remove bearer token from local storage when logging out
keycloak.onAuthLogout  = function() {
    localStorage.removeItem('bearer_token');
    keycloak.logout();
    window.location.reload(); 
}

