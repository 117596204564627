import React, {Component} from 'react';
import {Redirect, Route, Switch} from 'react-router-dom';
import {Container} from 'reactstrap';

import {
  AppAside,
  AppBreadcrumb,
  AppFooter,
  AppHeader,
  AppSidebar,
  AppSidebarFooter,
  AppSidebarForm,
  AppSidebarHeader,
  AppSidebarMinimizer,
  AppSidebarNav
} from '@coreui/react';
// sidebar nav config
import navigation from '../../_nav';
// routes config
import routes from '../../routes';
import DefaultAside from './DefaultAside';
import DefaultFooter from './DefaultFooter';
import DefaultHeader from './DefaultHeader';
import {fetchFromApi, fetchErrorHandler, isUndef} from '../../utils/utils.js';

const AbortController = window.AbortController;

class DefaultLayout extends Component {
  controller = new AbortController();
  constructor(props) {
    super(props);
    this.state = {environments: []};
  }

  componentWillUnmount() {
    this.controller.abort();
  }

  resetController() {
    this.controller.abort()
    this.controller = new AbortController();
  }

  componentDidMount() {
    this.resetController();
    fetchFromApi("environments", this.controller.signal).then(data => {
      if(!isUndef(data)){
        this.setState({environments: data});
      }
    }).catch(err => {
      fetchErrorHandler(err);
      return;
    });
  }

  getNav() {
    navigation.items[1].children = this.state.environments;
    navigation.items[1].children.forEach(function(element) {
      element.url = "/environments/" + element.Name;
      element.name = element.Name;
    });

    return navigation
  }
  render() {
    return (<div className="app">
      <AppHeader className="header-fixed">
        <DefaultHeader/>
      </AppHeader>
      <div className="app-body">
        <AppSidebar className="sidebar-fixed" display="lg">
          <AppSidebarHeader/>
          <AppSidebarForm/>
          <AppSidebarNav navConfig={this.getNav()} {...this.props}/>
          <AppSidebarFooter/>
          <AppSidebarMinimizer/>
        </AppSidebar>
        <main className="main">
          <AppBreadcrumb appRoutes={routes}/>
          <div className="container-fluid">
            <Switch>
              {
                routes.map((route, idx) => {
                  return route.component
                    ? (<Route key={idx} path={route.path} exact={route.exact} name={route.name} render={props => (<route.component {...props}/>)}/>)
                    : (null);
                },)
              }
              <Redirect from="/" to="/dashboard"/>
            </Switch>
          </div>
        </main>
      </div>
      <AppFooter>
        <DefaultFooter/>
      </AppFooter>
    </div>);
  }
}

export default DefaultLayout;
