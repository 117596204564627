import React, {Component} from 'react';
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Nav
} from 'reactstrap';
import PropTypes from 'prop-types';

import {AppAsideToggler, AppHeaderDropdown, AppNavbarBrand, AppSidebarToggler} from '@coreui/react';
import AuthContext from "../../AuthContext.js";

const propTypes = {
  children: PropTypes.node
};

const defaultProps = {};

class DefaultHeader extends Component {

  render() {

    // eslint-disable-next-line
    const {
      children,
      ...attributes
    } = this.props;

    return (
    <React.Fragment>
      <AppSidebarToggler className="d-lg-none" display="md" mobile />
      <a href="#" className="navbar-brand">
        <center>Kermit UI</center>
      </a>
      <Nav className="ml-auto" navbar>
        <AppHeaderDropdown direction="down">
          <DropdownToggle nav >
            <AuthContext.Consumer>
            {({ userProfile }) => (
              <strong>{userProfile.firstName}&nbsp;{userProfile.lastName}&nbsp;</strong>
            )}
            </AuthContext.Consumer>
            <AppAsideToggler className="d-md-down-none"/>
          </DropdownToggle>
          <DropdownMenu right style={{
              right: 'auto'
            }}>
            <DropdownItem tag="div" className="text-center">
              <AuthContext.Consumer>
              {({ userProfile }) => (
                <strong>Account: {userProfile.username}</strong>
              )}
              </AuthContext.Consumer>
            </DropdownItem>
            <DropdownItem href="/#/profile">
              <i className="fa fa-user"></i>
              Profile</DropdownItem>
            <DropdownItem href="/#/rbac">
              <i className="fa fa-wrench"></i>
              RBAC</DropdownItem>
            <DropdownItem divider />
            <AuthContext.Consumer>
            {({ logoutUrl }) => (
                <DropdownItem href={logoutUrl}>
                  <i className="fa fa-lock"></i>
                Logout</DropdownItem>
            )}
            </AuthContext.Consumer>
          </DropdownMenu>
        </AppHeaderDropdown>
      </Nav>
      {/* <AppAsideToggler className="d-lg-none" mobile /> */}
    </React.Fragment>);
  }
}

DefaultHeader.propTypes = propTypes;
DefaultHeader.defaultProps = defaultProps;

export default DefaultHeader;
