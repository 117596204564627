import React from 'react';
import AuthContext from "./AuthContext"
class AuthStore extends React.Component {

  state = {
    userProfile: this.props.userProfile,
    token: this.props.token,
    logoutUrl: this.props.logoutUrl
  }

  render() {
    // Pass down the state and component actions
    return (
        <AuthContext.Provider
            value={{
            userProfile: this.state.userProfile,
            token: this.state.token,
            logoutUrl: this.state.logoutUrl
            }}
        >
            {this.props.children}
        </AuthContext.Provider>
        );
  }
}

export default AuthStore